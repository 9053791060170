<template>
      <b-row>
<b-col>
  <b-row>
    <b-col lg="3" class="my-1">
      <b-form-group
          label=""
          label-for="filter-input"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col lg="3" class="my-1">
      <b-form-group
          v-model="sortDirection"
          label=""
          description=""
          class="mb-0"
          v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
        >
          <b-form-checkbox v-for="cab of sellers" :key="'cab-'+cab" :value="cab">{{cab}}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </b-col>
    <b-col lg="3" class="my-1">
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
      ></b-pagination>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-table
          :items="logs"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          show-empty
          small
          hover
          :busy="isLoading"
          :filter="filter"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner variant="primary" label="loading..."></b-spinner>
          </div>
        </template>


        <template #cell(art_wb)="{item}">
          <a :href="`https://www.wildberries.ru/catalog/${item.art_wb}/detail.aspx?targetUrl=RG&size=57323449`" target="_blank">{{item.art_wb}}</a>
        </template>
        <template #cell(prices)="{item}">
         <b>{{item.salePrice}}</b>={{ item.price }}-{{ item.discount }}%-{{ item.promocode }}%
        </template>
        <template #cell(result)="{item}">
          <span v-for="(r, indx) of item.result" :key="indx">{{r.event}}: {{r.status}}, </span>
        </template>
        <template #cell(dats)="{item}">
          add: {{item.dateAdd}}<br> upd: {{item.lastUpdate}}
        </template>

      </b-table>
    </b-col>
  </b-row>
</b-col>

      </b-row>

</template>

<style lang="scss">
</style>

<script>
export default {
  name: "global-products-log",
  data: () => ({
    isLoading:false,
    logs: [],
    sellers: ['onWbIP', 'onWbOOO', 'onWbViplinza'],
    fields: [
      {key: "art_wb", sortable: true, label: "art_wb"},
      {key: "prices", sortable: true, label: "Цены"},
      {key: "seller", sortable: true, label: "seller"},
      {key: "result", sortable: true, label: "result"},
      {key: "status", sortable: false, label: "status"},
      {key: "dats", sortable: false, label: "Даты"},
    ],
    currentPage: 1,
    totalRows: 1,
    perPage: 50,
    filter: null,
  }),
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    // await this.getLogsPrices()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getLogsPrices() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/report/getLogsPrices`,{
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
      .then(resp => resp.json())
      .then(resp => {
        this.logs = resp
        this.totalRows = resp.length
        this.isLoading=false
        // if (!resp.status) {
        //   this.error = { isError: true, message: resp.msg };
        // } else {
        //   this.error = { isError: false, message: "" };
        //   this.fedbackList = resp.data
        // }
      })

    }
  },
  computed: {
  },
};
</script>